import React from "react";

import Seo from "../components/seo";

const NotFoundPage = () => (
  <>
    <Seo title="404: Not found" />
    <h1>Cette page n’existe pas</h1>
    <p>
      C’est tout triste 😢.{" "}
      <a href="/" title="Retourner à l’accueil" style={{ color: "black" }}>
        Retourner à l’accueil
      </a>
      .
    </p>
  </>
);

export default NotFoundPage;
