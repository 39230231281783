import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";

import "./seo.css";

const defaultDescription =
  "My PrEP vous rappelle de prendre votre PrEP. Disponible sur iPhone et Android.";
const defaultTitle = "My PrEP";
const defaultAuthor = "@myprepapp";

const SEO = ({
  description = defaultDescription,
  lang,
  meta,
  title = defaultTitle,
}) => {
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={title}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: defaultAuthor,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
      ].concat(meta)}
    >
      <link
        href="https://fonts.googleapis.com/css?family=Arvo:400,700|Raleway:100,200,300,400,500,600,700|Roboto+Slab:100,300,400,700|Ubuntu:300,400,500|Varela+Round&display=swap"
        rel="stylesheet"
      />
      <link rel="icon" href="/favicon.png"></link>
      <script
        src="https://cdn.usefathom.com/script.js"
        spa="auto"
        site="IVSUWTVM"
        defer
      ></script>
    </Helmet>
  );
};

SEO.defaultProps = {
  lang: `fr`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
